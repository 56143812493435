.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #EAEAEA;
    background: #FFF;

}

.block {
    display: flex;
    align-items: center;
    gap: 48px;
    height: 100%;
}

.block img {
    margin-right: 16px;
}

.block a {
    color: #2A2E37;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    transition: .5s all ease;
}

.block div {
    transition: .5s all ease;
}

.block:hover div {
    color: #979797;
    transition: .5s all ease;
}

.block:hover a {
    color: #979797;
    transition: .5s all ease;
}

.header_link:hover {
    color: #2A2E37!important;
}

.user_round {
    width: 60px;
    height: 60px;
    background: #EEEFEF;
    border: 1.5px solid #EEEFEF;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #979797;
    text-transform: uppercase;
}

